<template>
  <div class="flexcolumnstart v-project-detail">
    <section-view>
      <div class="header">
        <div class="title">
          {{ item.title }}
        </div>
        <span
          :class="[
            item.status != 'RUNNING' ? 'status status-disable' : 'status',
          ]"
        >
          {{ statusText(item.status) }}
        </span>
        <div class="row flexrowstart">
          <span class="row-title">主要目的</span>
          <div class="row-value ">{{ item.purpose }}</div>
        </div>
        <div style="height:14px"></div>
        <div class="row flexrowstart">
          <span class="row-title">登记号</span>
          <div class="row-value">{{ item.registrationNo }}</div>
        </div>
        <div style="height:14px"></div>
        <div class="row flexrowstart">
          <span class="row-title">适应证</span>
          <div class="row-value ">{{ item.indication }}</div>
        </div>
      </div>
    </section-view>
    <section-view>
      <div class="detail">
        <div class="section-title">
          <div class="height20"></div>
          招募标准
          <div class="height40"></div>
          <div class="section-subtitle">
            纳入标准
            <div class="height20"></div>
            <div class="section-content multiline">{{ item.inStandard }}</div>
          </div>
        </div>
        <div class="height60"></div>
        <!--  -->
        <div class="section-subtitle">
          排除标准
          <div class="section-content multiline">{{ item.outStandard }}</div>
        </div>
        <div class="height80"></div>
        <!--  -->
        <div class="section-title">
          研究中心
          <div class="height28"></div>
          <div class="section-content ">
            {{ item.address }}
          </div>
        </div>
      </div>
    </section-view>
    <!--    注： -->
    <section-view
      style="background:transparent;"
      v-if="item.remark && item.remark.length > 0"
    >
      <div class="ps-title">
        注：
      </div>
      <div class="height20"></div>
      <div class="ps-content multiline">{{ item.remark }}</div>
    </section-view>
    <div class="height80"></div>
    <div class="height80"></div>
    <div class="g-safe-area-bottom"></div>
    <submit-btn
      v-if="this.item.questionnaireId"
      @click="toJoin()"
      style="position:fixed;bottom:0;left:0;"
      title="填写报名表"
    />
  </div>
</template>

<script>
import SectionView from "@/components/section-view.vue";
import SubmitBtn from "@/components/submit-btn.vue";
export default {
  components: { SectionView, SubmitBtn },
  data() {
    return {
      item: {
        // address: "地址"
        // createTime: "2022-02-08 17:09:58"
        // drug: "药物"
        // id: 2
        // inStandard: "纳入标准"
        // indication: "适应证"
        // isOnline: true
        // orderby: 0
        // outStandard: "排除标准"
        // purpose: "目的"
        // questionnaireId: "1010"
        // questionnaireName: null
        // recruitmentType: null
        // registrationNo: "登记号"
        // status: "RUNNING"
        // title: "测试标题02"
        // updateTime: "2022-02-17 10:03:23"
      },
    };
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background:#F7F9FC");
  },
  beforeDestroy() {
    document.querySelector("body").setAttribute("style", "");
  },
  created() {},
  mounted() {
    console.log(`this.$route.query: ${JSON.stringify(this.$route.query)}`);
    this.getDetailRequest();
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    toJoin() {
      console.log(`toJoin`);

      this.$router.push({
        path: "/clinicalVolunteer/join",
        query: {
          // questionnaireId: "2022021816241239428562",
          questionnaireId: this.item.questionnaireId,
          id: this.item.id,
        },
        // query: { questionnaireId: this.item.questionnaireId },
      });
    },
    statusText: function(status) {
      return status == "RUNNING" ? "招募中" : "已结束";
    },
    getDetailRequest() {
      this.$axios
        .get(
          `/clinical/recruitment/detail/online?id=${this.$route.query.id}`,
          {}
        )
        .then((res) => {
          this.item = res.data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-project-detail {
}

.header {
  .title {
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #040814;
    line-height: 45px;
  }
  .status {
    font-size: 32px;
    font-weight: 500;
    color: #f87c2e;
    padding: 12px 24px;
    background: #fff4ed;
    border-radius: 14px;
    align-self: flex-start;
    flex: 0 0 auto;
    width: auto;
    margin: 20px 0;
  }
  .status-disable {
    color: #c4c6d0;
    background: #eef1f5;
  }
  .row {
    font-size: 28px;
    font-weight: 400;
    color: #040814;

    .row-title {
      color: #4f5265;
      width: 162px;
      flex-shrink: 0;
    }
  }
}
.detail {
  font-size: 28px;
  font-weight: 400;
  color: #4f5265;
  line-height: 40px;
}
.section-title {
  font-size: 36px;
  font-weight: 500;
  color: #040814;
  line-height: 50px;
}
.section-subtitle {
  font-size: 30px;
  font-weight: 600;
  color: #4f5265;
  line-height: 42px;
}
.section-content {
  font-size: 28px;
  font-weight: 400;
  color: #4f5265;
  line-height: 56px;
}
.ps-title {
  font-size: 32px;
  font-weight: 500;
  color: #040814;
  line-height: 45px;
}
.ps-content {
  font-size: 28px;
  font-weight: 400;
  color: #4f5265;
  line-height: 40px;
}
.multiline {
  white-space: pre-wrap;
}
</style>
